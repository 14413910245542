import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

import DreemForBiopharma from '../components/dreem/DreemForBiopharms'
import DreemHeadbandPageHero from '../components/dreem/DreemHeadbandHero'
import DreemPSGQuality from '../components/dreem/DreemPSGQuality'
import DreemSleepMonitoring from '../components/dreem/DreemSleepMonitoring'
import DreemApp from '../components/dreem/DreemApp'
// import DreemAccess from '../components/dreem/DreemAccess'
import DreemAdvanceAnalytics from '../components/dreem/DreemAdvanceAnalytics'
import DreemCompleteStudy from '../components/dreem/DreemCompleteStudy'
import DreemAccordions from '../components/dreem/DreemAccordions'
import DreemFAQ from '../components/dreem/DreemFAQ'

const DreemHeadbandPageTemplate = ({
    title,
    description,
    dreemAppImage,
    headbandImage,
    psgImage,
    studySupportImage,
    advancedAnalyticsImage,
    completeStudySupportImage,
    beaconPortalImage,
    headbandImageTwo,
    signalQualityImage,
    psgAccuracyImage,
}) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <DreemHeadbandPageHero title={title} description={description} />
            <DreemForBiopharma
                dreemAppImage={dreemAppImage}
                headbandImage={headbandImage}
                studySupportImage={studySupportImage}
                beaconPortalImage={beaconPortalImage}
            />
            <DreemPSGQuality
                psgImage={psgImage}
                headbandImage={headbandImageTwo}
            />
            <DreemSleepMonitoring />
            <DreemApp />
            {/* <div className="hidden md:block">
                <DreemAccess />
            </div> */}
            <DreemAdvanceAnalytics image={advancedAnalyticsImage} />
            <DreemCompleteStudy image={completeStudySupportImage} />
            <DreemAccordions
                signalQualityImage={signalQualityImage}
                psgAccuracyImage={psgAccuracyImage}
            />
            <DreemFAQ />
        </div>
    )
}

const svgImage = PropTypes.shape({
    extension: PropTypes.string,
    publicURL: PropTypes.string,
})

DreemHeadbandPageTemplate.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    dreemAppImage: svgImage,
    headbandImage: svgImage,
    psgImage: svgImage,
    studySupportImage: svgImage,
    advancedAnalyticsImage: svgImage,
    completeStudySupportImage: svgImage,
    beaconPortalImage: svgImage,
    headbandImageTwo: svgImage,
    signalQualityImage: svgImage,
    psgAccuracyImage: svgImage,
}

const DreemHeadbandPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark

    return (
        <Layout title={frontmatter.title}>
            <DreemHeadbandPageTemplate
                title={frontmatter.title}
                description={frontmatter.description}
                dreemAppImage={frontmatter.dreemAppImage}
                headbandImage={frontmatter.headbandImage}
                psgImage={frontmatter.psgImage}
                studySupportImage={frontmatter.studySupportImage}
                advancedAnalyticsImage={frontmatter.advancedAnalyticsImage}
                completeStudySupportImage={
                    frontmatter.completeStudySupportImage
                }
                beaconPortalImage={frontmatter.beaconPortalImage}
                headbandImageTwo={frontmatter.headbandImageTwo}
                signalQualityImage={frontmatter.signalQualityImage}
                psgAccuracyImage={frontmatter.psgAccuracyImage}
            />
        </Layout>
    )
}

DreemHeadbandPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
}

export default DreemHeadbandPage

export const dreemHeadbandPagePageQuery = graphql`
    query DreemheadbandPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            frontmatter {
                title
                description

                headbandImage {
                    extension
                    publicURL
                }
                headbandImageTwo {
                    extension
                    publicURL
                }
                dreemAppImage {
                    extension
                    publicURL
                }
                alfinTwoImage {
                    extension
                    publicURL
                }
                psgImage {
                    extension
                    publicURL
                }
                studySupportImage {
                    extension
                    publicURL
                }
                advancedAnalyticsImage {
                    extension
                    publicURL
                }
                completeStudySupportImage {
                    extension
                    publicURL
                }
                beaconPortalImage {
                    extension
                    publicURL
                }
                signalQualityImage {
                    extension
                    publicURL
                }
                psgAccuracyImage {
                    extension
                    publicURL
                }
            }
        }
    }
`
