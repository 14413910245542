import React from 'react'
import PropTypes from 'prop-types'

import DreemForBiopharmaCarousel from './DreemForBiopharmCarousel'
import DreemHeader from './DreemHeader'

export default function DreemForBiopharma({
    dreemAppImage,
    headbandImage,
    studySupportImage,
    beaconPortalImage,
}) {
    return (
        <div className="bg-pewter-100 w-full py-30 px-30 min-h-screen">
            <DreemHeader
                title="Dreem for Clinical Trials"
                description=""
            />
            <DreemForBiopharmaCarousel
                dreemAppImage={dreemAppImage}
                headbandImage={headbandImage}
                studySupportImage={studySupportImage}
                beaconPortalImage={beaconPortalImage}
            />
        </div>
    )
}

const svgImage = PropTypes.shape({
    extension: PropTypes.string,
    publicURL: PropTypes.string,
})

DreemForBiopharma.propTypes = {
    headbandImage: svgImage,
    studySupportImage: svgImage,
    dreemAppImage: svgImage,
    beaconPortalImage: svgImage,
}
