import React from 'react'
import PropTypes from 'prop-types'

import DreemHeader from './DreemHeader'
import DreemBulletItem from './DreemBulletItem'

import Image from '../Image'

export default function DreemCompleteStudy({ image }) {
    return (
        <div className=" bg-pewter-100 w-full">
            <DreemHeader title="Complete study support" />
            <div className="w-full px-30 mx-auto max-w-1264 ">
                <div className="mt-30 mx-auto flex flex-col md:flex-row mb-32">
                    <div
                        className="md:w-1/2 sm:w-full mr-12"
                        style={{
                            marginRight: '111px',
                        }}
                    >
                        <div style={{ minWidth: '300px' }}>
                            <Image image={image} />
                        </div>
                    </div>
                    <div className="md:w-1/2 sm:w-full">
                        <div className="text-pewter-700 mb-20 text-16 mt-80">
                            <ul style={{ listStyleType: 'disc' }}>
                                <DreemBulletItem
                                    text="Account Management - A dedicated account
                                manager to ensure the sucess of your research
                                throughout the whole protocol."
                                />
                                <DreemBulletItem
                                    text="Dedicated documentation - From detailed
                                descriptions of the headband, how to’s, to
                                protocol information. We provide you with
                                documentation at every step of the way: before,
                                during and after your participants’ visits."
                                />
                                <DreemBulletItem
                                    text="Ongoing support - A technical team
                                supports you throughout the whole process."
                                />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

DreemCompleteStudy.propTypes = {
    image: PropTypes.shape({
        extension: PropTypes.string,
        publicURL: PropTypes.string,
    }),
}
