import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Check, Diamond } from 'lucide-react'

import DreemHeader from './DreemHeader'

import { theme } from '../../../tailwind.config'

const Header = ({ name, className, last }) => {
    return (
        <td
            style={{
                borderBottom: `1px solid ${theme.extend.colors.pewter[200]}`,
                borderRight: last
                    ? 'none'
                    : `1px solid ${theme.extend.colors.pewter[200]}`,
            }}
            className={cx(className, 'whitespace-no-wrap')}
        >
            <div
                className="text-18 font-bold text-pewter-700"
                style={{
                    padding: '32px',
                }}
            >
                {name}
            </div>
        </td>
    )
}

Header.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    last: PropTypes.bool,
}

const Cell = ({ checked, last }) => {
    return (
        <td
            style={{
                padding: '32px',
                borderLeft: `1px solid ${theme.extend.colors.pewter[200]}`,
                borderRight: last
                    ? 'none'
                    : `1px solid ${theme.extend.colors.pewter[200]}`,
            }}
        >
            {checked ? (
                <Check
                    color={theme.extend.colors.teal[600]}
                    style={{
                        color: 'transparent',
                        margin: 'auto',
                    }}
                />
            ) : (
                <Diamond
                    color={theme.extend.colors.pewter[200]}
                    style={{
                        color: 'transparent',
                        margin: 'auto',
                        height: '12px',
                    }}
                />
            )}
        </td>
    )
}

Cell.propTypes = {
    checked: PropTypes.bool,
    last: PropTypes.bool,
}

export default function DreemSleepMonitoring() {
    const tableBody = [
        {
            name: 'Patient experience',
            polysomnography: false,
            actigraphy: true,
            dreem: true,
        },
        {
            name: 'Brain waves monitoring (EEG)',
            polysomnography: true,
            actigraphy: false,
            dreem: true,
        },
        {
            name: 'Longitudinal monitoring',
            polysomnography: false,
            actigraphy: true,
            dreem: true,
        },
        {
            name: 'Automatic sleep staging',
            polysomnography: false,
            actigraphy: true,
            dreem: true,
        },
        {
            name: 'Scalable',
            polysomnography: false,
            actigraphy: true,
            dreem: true,
        },
        {
            name: 'Accurate',
            polysomnography: true,
            actigraphy: false,
            dreem: true,
        },
    ]

    return (
        <div
            className="bg-pewter-100 pt-30 w-full px-30"
            style={{
                paddingBottom: '80px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <DreemHeader
                title="At-home sleep monitoring"
                description="A reliable alternative to PSG to measure and diagnose sleep patterns"
            />
            <div
                style={{
                    overflowX: 'auto',
                }}
            >
                <div
                    className="mt-100 mx-auto max-w-1264"
                    style={{
                        border: `1px solid ${theme.extend.colors.pewter[200]}`,
                        borderRadius: '8px',
                        width: '100%',
                        overflow: 'auto',
                    }}
                >
                    <table className="w-full">
                        <thead>
                            <tr>
                                <Header name="Features" />
                                <Header
                                    name="Polysomnography"
                                    className="text-center"
                                />
                                <Header
                                    name="Actigraphy"
                                    className="text-center"
                                />
                                <Header
                                    name="Dreem 3"
                                    className="text-center"
                                    last
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {tableBody.map((row) => (
                                <tr key={row.name}>
                                    <td>
                                        <div
                                            className="text-16 font-semibold text-pewter-500"
                                            style={{
                                                padding: '32px',
                                            }}
                                        >
                                            {row.name}
                                        </div>
                                    </td>
                                    <Cell checked={row.polysomnography} />
                                    <Cell checked={row.actigraphy} />
                                    <Cell checked={row.dreem} last />
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
