import React, { useState } from 'react'
import Markdown from 'react-markdown'

import DreemHeader from './DreemHeader'
import { faqs } from './DreemFaqs'
import { theme } from '../../../tailwind.config'

export default function DreemFAQ() {
    const [selectedFaq, setSelectedFaq] = useState('general')
    return (
        <div className="bg-pewter-100  w-full px-30 py-30">
            <DreemHeader title="FAQ" />
            <div className="flex flex-col max-w-1264 w-full mx-auto">
                <div
                    className="p-12 flex justify-between  cursor-pointer items-center mb-40"
                    style={{
                        overflowX: 'auto',
                    }}
                >
                    {Object.keys(faqs).map((key) => {
                        const selected = key === selectedFaq
                        return (
                            <div
                                className="cursor-pointer whitespace-no-wrap p-12"
                                onClick={() => {
                                    setSelectedFaq(key)
                                }}
                                style={{
                                    borderBottom: selected
                                        ? `2px solid ${theme.extend.colors.pink[500]}`
                                        : null,
                                    color: selected
                                        ? theme.extend.colors.pink[500]
                                        : 'black',
                                }}
                                key={key}
                            >
                                {faqs[key].title}
                            </div>
                        )
                    })}
                </div>
                <div className="ml-12">
                    <ul>
                        {faqs[selectedFaq].content.map(
                            ({ question, answer, linkTarget }, index) => {
                                return (
                                    <div key={question}>
                                        <div className="flex">
                                            <div className="mr-12 text-18 font-bold mb-12 flex">
                                                <div
                                                    style={{
                                                        color:
                                                            theme.extend.colors
                                                                .pink[500],
                                                        marginRight: '8px',
                                                    }}
                                                >{`${index + 1}.`}</div>{' '}
                                                <div>{question}</div>
                                            </div>
                                        </div>
                                        <div className="mb-40 text-pewter-700">
                                            <Markdown
                                                linkTarget={linkTarget}
                                                source={answer}
                                                className="rich-text"
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}
