import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import IntroText from '../IntroText'
import ContactButton from '../ContactButton'
import PageBackgroundImage from '../PageBackgroundImage'

// import { useAlert } from '../../templates/alert-notification'

export default function DreemHeadbandPageHero({ title, description }) {
    // TODO update with dismissable banner

    // const alertState = useAlert()
    // const alert = alertState?.alert
    // const alertDismissed = alertState?.alertDismissed

    // const addAlertMargin = !!alert && !alertDismissed

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                minHeight: '65vh',
                marginBottom: '32px',
                paddingLeft: '30px',
                paddingRight: '30px',
            }}
        >
            <PageBackgroundImage
                image={{
                    extension: 'svg',
                    publicURL:
                        '/static/3b2499b5f02c2403bb16ba798d3d55b1/bg-our-team.svg',
                }}
            />
            <div
                className={cx('mx-96 max-w-600')}
                // TODO update with dismissable banner
                style={{
                    // marginTop: `calc(40% - ${
                    //     addAlertMargin ? '120px' : '60px'
                    // })`,
                    marginTop: 'calc(40% - 120px)',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    alignItems: 'center',
                }}
            >
                <div className="text-pewter-700 text-40 font-semibold mb-32">
                    {title}
                </div>
                <div className="mb-32">
                    <IntroText className="mb-32">{description}</IntroText>
                </div>
                <ContactButton className="w-150" />
            </div>
        </div>
    )
}

DreemHeadbandPageHero.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
}
