export const publications = [
    {
        title: 'Arnal PJ et al. SLEEP.',
        description:
            'The Dreem Headband compared to polysomnography for electroencephalographic signal acquisition and sleep staging',
        year: '2020',
        url:
            'https://academic.oup.com/sleep/article/43/11/zsaa097/5841249?login=false',
    },
    {
        title: 'Waeber et al.',
        description:
            'Acoustic stimulation time-locked to the beginning of sleep apnea events reduces oxygen desaturations: a pilot-study',
        year: '2020',
        url:
            'https://www.sciencedirect.com/science/article/pii/S1389945720305566?via%3Dihub',
    },
    {
        title: 'Chambon et al.',
        description:
            'DOSED: A deep learning approach to detect multiple sleep micro-events in EEG signal',
        year: '2019',
        url:
            'https://www.sciencedirect.com/science/article/abs/pii/S0165027019301013?via%3Dihu',
    },
    {
        title: 'Chambon et al.',
        description:
            'A Deep Learning Architecture for Temporal Sleep Stage Classification Using Multivariate and Multimodal Time Series',
        year: '2018',
        url: 'https://ieeexplore.ieee.org/document/8307462',
    },
    {
        title: 'Debellemaniere et al.',
        description:
            'Performance of an Ambulatory Dry-EEG Device for Auditory Closed-Loop Stimulation of Sleep Slow Oscillations in the Home Environment',
        year: '2018',
        url:
            'https://www.frontiersin.org/articles/10.3389/fnhum.2018.00088/full',
    },
    {
        title: 'Thorey et al.',
        description:
            'The dreem2 headband as an alternative to polysomnography for eeg signal acquisition, breathing and heart rate monitoring and sleep staging in healthy subjects technology/technical',
        year: '2019',
        url:
            'https://worldsleepcongress.com/wp-content/uploads/2019/09/WS19-Poster-abstracts-by-author.pdf',
    },
]
