import React from 'react'
import PropTypes from 'prop-types'

import { theme } from '../../../tailwind.config'

export default function DreemBulletItem({ text }) {
    return (
        <li
            style={{ color: theme.extend.colors.purple[700] }}
            className="mb-12"
        >
            <div className="text-16 text-pewter-700">{text}</div>
        </li>
    )
}

DreemBulletItem.propTypes = {
    text: PropTypes.string,
}
