import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { CheckCircle2 } from 'lucide-react'

import DreemHeader from './DreemHeader'

import { theme } from '../../../tailwind.config'
import AppStore from '../../img/inline/badge_app-store.svg'
import GooglePlayStore from '../../img/inline/badge_google-play.svg'
import screenshots from '../../img/inline/dreem-app.png'

export default function DreemApp() {
    const valueProps = [
        {
            header: 'User Centric',
            description:
                'Easy start and stop of the night, in both inpatient and outpatient settings',
        },
        {
            header: 'Immediate feedback',
            description:
                'Ensures that the headband is correctly placed on the head prior to data collection',
        },
        {
            header: 'Increased engagement',
            description:
                'With assessment data made available after each night with the headband',
        },
        {
            header: 'Direct synchronisation',
            description:
                'A wireless system transfers the aggregated metrics from the headband to the app',
        },
    ]

    return (
        <div className="w-full px-30 max-w-1264 py-30">
            <DreemHeader title="The Beacon Pal App" />
            <div className="mt-100 mx-auto  flex flex-col md:flex-row mb-32">
                <div
                    className="flex flex-col  justify-center"
                    style={{ marginRight: '64px', minWidth: '300px' }}
                >
                    <img className={cx('max-w-full')} src={screenshots} />
                </div>
                <div>
                    <div className="text-pewter-500 text-28 font-bold mb-20 mt-80 sm:text-center md:text-left">
                        Intuitive mobile experience for participants & investigators
                    </div>
                    <div className="text-pewter-700 font-medium mb-20 text-20">
                        <div>1. Download the Beacon Pal app</div>
                        <div>2. Pair it with the Dreem headband</div>
                        <div>3. Connect to WiFi</div>
                        <div>4. Give it to subjects</div>
                        <div>5. Start collecting data</div>
                    </div>
                    <div className="flex md:flex-col">
                        <a
                            className="mb-20 mr-20"
                            href="https://apps.apple.com/tt/app/beacon-pal/id6477046918"
                            target="__blank"
                        >
                            <AppStore />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.beaconbiosignals.beaconpal"
                            target="__blank"
                        >
                            <GooglePlayStore />
                        </a>
                    </div>
                </div>
            </div>
            <div>
                <div className="flex flex-col md:flex-row">
                    {valueProps.map(({ header, description }) => (
                        <div className="flex mb-12" key={header}>
                            <CheckCircle2
                                size="54px"
                                color={theme.extend.colors.purple[700]}
                                style={{
                                    color: 'transparent',
                                    marginRight: '12px',
                                    marginTop: '-15px',
                                }}
                            />
                            <div>
                                <div className="text-pewter-700 text-18 font-semibold mb-20">
                                    {header}
                                </div>
                                <div className="text-16">{description}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

DreemApp.propTypes = {
    image: PropTypes.shape({
        extension: PropTypes.string,
        publicURL: PropTypes.string,
    }),
}
