import React from 'react'

import PropTypes from 'prop-types'
import cx from 'classnames'

import DreemHeader from './DreemHeader'
import Image from '../Image'

import DreemBulletItem from './DreemBulletItem'
export default function DreemAdvanceAnalytics({ image }) {
    return (
        <div
            className="bg-pewter-100  w-full "
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <DreemHeader title="Advanced Analytics" description="" />
            <div
                className="mt-20 mx-auto max-w-1264 w-full border-pewter-200 flex md:flex-row flex-col"
                style={{
                    paddingRight: '30px',
                    paddingLeft: '30px',
                }}
            >
                <div className="md:w-1/2 sm:w-full">
                    <div>
                        <div
                            style={{
                                fontSize: '36px',
                                marginBottom: '30px',
                            }}
                            className="text-pewter-700 font-bold mt-80"
                        >
                            Machine Learning For Sleep
                        </div>
                        <ul
                            style={{
                                listStyleType: 'disc',
                                paddingRight: '30px',
                                paddingLeft: '30px',
                            }}
                        >
                            <DreemBulletItem text="Automatically calculate sleep endpoints atop incoming biosignal recordings (TST, WASO, etc.)" />
                            <DreemBulletItem text="Measure signal quality and recording compliance to ensure high-quality analysis results" />
                        </ul>
                    </div>
                    <div>
                        <div
                            style={{
                                fontSize: '36px',
                                marginBottom: '30px',
                            }}
                            className="text-pewter-700 font-bold mt-80"
                        >
                            Data management system
                        </div>
                        <ul
                            style={{
                                listStyleType: 'disc',
                                paddingRight: '30px',
                                paddingLeft: '30px',
                            }}
                        >
                            <DreemBulletItem text="Easy access to your recordings" />
                            <DreemBulletItem
                                text="Access to secured platform for compliance and
                                data quality assessments"
                            />
                            <DreemBulletItem text="Integrated cloud data management" />
                        </ul>
                    </div>
                </div>
                <div className="md:w-1/2 sm:w-full">
                    <Image image={image} className={cx('w-full h-full')} />
                </div>
            </div>
        </div>
    )
}

DreemAdvanceAnalytics.propTypes = {
    image: PropTypes.shape({
        extension: PropTypes.string,
        publicURL: PropTypes.string,
    }),
}
