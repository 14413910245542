import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Image from '../Image'

import HeadbandSvg from '../../img/inline/headband.svg'
import PhoneSvg from '../../img/inline/phone.svg'
import BooksSvg from '../../img/inline/books.svg'
import ComputerSvg from '../../img/inline/computer.svg'

import './bar.css'

const svgImage = PropTypes.shape({
    extension: PropTypes.string,
    publicURL: PropTypes.string,
})

const ProgressBar = () => {
    return (
        <div className="meter">
            <span style={{ width: '100%' }}>
                <span className="progress" />
            </span>
        </div>
    )
}

const Copy = ({ copy, selected }) => {
    return selected ? (
        <div>
            <div
                className="text-pewter-700 text-12 md:text-16 mt-4 md:mt-64 mb-32 md:mb-0"
                style={{ height: '50px' }}
            >
                {copy}
            </div>
            <div className="block md:hidden">
                <ProgressBar />
            </div>
        </div>
    ) : null
}

Copy.propTypes = {
    copy: PropTypes.string,
    selected: PropTypes.bool,
}

const CardContainer = ({ index, selected, setSelected, card }) => {
    useEffect(() => {
        if (selected === index) {
            const interval = setInterval(() => {
                setSelected(index === 3 ? 0 : index + 1)
            }, 5000)
            return () => clearInterval(interval)
        }
    }, [index, selected, setSelected])

    return (
        <>
            <div>
                <div
                    style={{
                        boxShadow:
                            index === selected
                                ? '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
                                : null,
                    }}
                    onClick={() => {
                        setSelected(index)
                    }}
                    className={cx(
                        'text-pewter-500 text-12 md:text-18 py-36 px-8 md:px-30 cursor-pointer hidden md:block',
                        {
                            'bg-white shadow-lg': index === selected,
                        }
                    )}
                    key={card.title}
                >
                    <div className="flex">
                        <div style={{ marginRight: '4px' }}>{card.icon}</div>
                        <div className="whitespace-no-wrap">{card.title}</div>
                    </div>
                </div>
                <div>{selected === index ? <ProgressBar /> : null}</div>
            </div>

            {selected === index ? (
                <div className="md:hidden">
                    <div className="flex">
                        <div style={{ marginRight: '4px' }}>{card.icon}</div>
                        <div className="whitespace-no-wrap">{card.title}</div>
                    </div>
                </div>
            ) : null}
        </>
    )
}

CardContainer.propTypes = {
    index: PropTypes.number,
    selected: PropTypes.number,
    setSelected: PropTypes.func,
    card: PropTypes.shape({
        title: PropTypes.string,
        icon: svgImage,
    }),
}

export default function DreemForBiopharmaCarousel({
    dreemAppImage,
    headbandImage,
    studySupportImage,
    beaconPortalImage,
}) {
    const [selected, setSelected] = useState(0)

    const cards = [
        { title: 'The Headband', icon: <HeadbandSvg /> },
        {
            title: 'Beacon Pal App',
            icon: <PhoneSvg style={{ color: 'transparent' }} />,
        },
        {
            title: 'Polysomnogram Data',
            icon: <ComputerSvg style={{ color: 'transparent' }} />,
        },
        { title: 'Study Support', icon: <BooksSvg /> },
    ]

    const images = [
        headbandImage,
        dreemAppImage,
        beaconPortalImage,
        studySupportImage,
    ]

    const copy = [
        'A reliable alternative to PSG to measure and diagnose sleep patterns.',
        'Pair iOS devices with the Dreem Headband',
        '',
        '',
    ]

    return (
        <div className="flex flex-col-reverse md:flex-row md:justify-between mt-30 md:mt-100 mx-auto max-w-1264 w-full text-center items-center h-auto md:min-h-650">
            <div className="w-250 md:flex md:flex-col">
                <div className="flex flex-row md:flex-col justify-center mb-32 md:mb-0">
                    {cards.map((card, index) => (
                        <CardContainer
                            key={card.title}
                            index={index}
                            selected={selected}
                            setSelected={setSelected}
                            card={card}
                        />
                    ))}
                </div>
                {copy.map((currentCopy, index) => (
                    <Copy
                        key={`${currentCopy}-${index}`}
                        copy={currentCopy}
                        selected={selected === index}
                    />
                ))}
            </div>
            <div className="flex justify-center">
                <Image image={images[selected]} className="dreem-slide-image" />
            </div>
        </div>
    )
}

DreemForBiopharmaCarousel.propTypes = {
    headbandImage: svgImage,
    studySupportImage: svgImage,
    dreemAppImage: svgImage,
    beaconPortalImage: svgImage,
}
