import React from 'react'
import PropTypes from 'prop-types'

export default function DreemHeader({ title, description }) {
    return (
        <div
            className="text-center"
            style={{
                marginTop: '100px',
            }}
        >
            <div className="text-purple-700 text-40 font-semibold mb-24">
                {title}
            </div>
            <div className="text-pewter-700 text-18">{description}</div>
        </div>
    )
}

DreemHeader.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
}
