import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ArrowDownCircle, ArrowUpCircle, Link } from 'lucide-react'

import { theme } from '../../../tailwind.config'
import { publications } from './DreemPublications'
import Image from '../Image'

export default function DreemAccordions({
    signalQualityImage,
    psgAccuracyImage,
}) {
    const [validationIsOpen, setValidationIsOpen] = useState(false)
    const [publicationsIsOpen, setPublicationsIsOpen] = useState(false)
    return (
        <section className="bg-purple-700 w-full text-white">
            <div className="border-b border-solid border-purple-500">
                <div
                    className="p-12 flex justify-between max-w-1264 w-full mx-auto cursor-pointer items-center"
                    onClick={() => setValidationIsOpen(!validationIsOpen)}
                >
                    <div className="text-28">Independent Review</div>
                    {validationIsOpen ? (
                        <ArrowUpCircle
                            color={theme.extend.colors.purple[200]}
                            style={{ color: 'transparent' }}
                        />
                    ) : (
                        <ArrowDownCircle
                            color={theme.extend.colors.purple[200]}
                            style={{ color: 'transparent' }}
                        />
                    )}
                </div>
            </div>
            {validationIsOpen ? (
                <div className="bg-white text-pewter-500 p-24">
                    <div className="p-12 flex justify-between max-w-1264 w-full mx-auto flex-col">
                        <div className="text-28 font-semibold mb-12">
                            Latest validation in top peer-reviewed journal in
                            SLEEP
                        </div>
                        <div className="text-18 text-pewter-700">
                            Recently published (Arnal et al. 2020), a
                            peer-reviewed study showed that the Dreem headband
                            achieves a level of analysis as accurate as the PSG
                            for measurement, and experts for sleep staging
                            analysis. To learn more about the study, go through
                            the paper{' '}
                            <a
                                className="text-blue-400"
                                href="https://academic.oup.com/sleep/article/43/11/zsaa097/5841249?login=false"
                            >
                                here.
                            </a>
                        </div>
                        <div className="flex flex-row flex-wrap justify-center py-36">
                            <div className="lg:w-1/2 sm:w-full pb-36">
                                <div className="text-24 font-semibold mb-36 whitespace">
                                    The EEG signal quality is similar to a PSG
                                </div>
                                <Image
                                    className="w-full lg:w-auto"
                                    image={signalQualityImage}
                                />
                            </div>
                            <div className="lg:w-1/2 sm:w-full">
                                <div className="text-24 font-semibold mb-12 col-start-2">
                                    Dreem headband & algorithms have a similar
                                    accuracy to sleep experts scoring on PSG
                                </div>
                                <Image
                                    className="w-full lg:w-auto"
                                    image={psgAccuracyImage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            <div className="border-b border-solid border-purple-500">
                <div
                    className="p-12 flex justify-between max-w-1264 w-full mx-auto cursor-pointer items-center"
                    onClick={() => setPublicationsIsOpen(!publicationsIsOpen)}
                >
                    <div className="text-28">Our Publications</div>
                    {publicationsIsOpen ? (
                        <ArrowUpCircle
                            color={theme.extend.colors.purple[200]}
                            style={{ color: 'transparent' }}
                        />
                    ) : (
                        <ArrowDownCircle
                            color={theme.extend.colors.purple[200]}
                            style={{ color: 'transparent' }}
                        />
                    )}
                </div>
            </div>
            {publicationsIsOpen ? (
                <div className="bg-white text-pewter-700">
                    <div className="p-12 flex justify-between max-w-1264 w-full mx-auto flex-wrap">
                        {publications.map((publication) => {
                            return (
                                <div
                                    key={publication.title}
                                    className="bg-pewter-100 flex flex-col"
                                    style={{
                                        margin: '24px',
                                        padding: '12px',
                                        borderRadius: '8px',
                                        width: '300px',
                                    }}
                                >
                                    <div className="text-18 font-semibold mb-12">
                                        {publication.title}
                                    </div>
                                    <div>{publication.description}</div>
                                    <div className="flex justify-between mt-24">
                                        <div>{publication.year}</div>
                                        <a
                                            href={publication.url}
                                            target="__blank"
                                        >
                                            <Link
                                                color={
                                                    theme.extend.colors
                                                        .teal[500]
                                                }
                                                style={{ color: 'transparent' }}
                                            />
                                        </a>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            ) : null}
        </section>
    )
}

DreemAccordions.propTypes = {
    signalQualityImage: PropTypes.shape({
        extension: PropTypes.string,
        publicURL: PropTypes.string,
    }),
    psgAccuracyImage: PropTypes.shape({
        extension: PropTypes.string,
        publicURL: PropTypes.string,
    }),
}
