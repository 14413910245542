export const faqs = {
    general: {
        title: 'General',
        content: [
            {
                question:
                    'I would like to start a study using the headband, who should I contact?',
                answer:
                    'If you want to start a study using the headband, please send us a request using this link: https://beacon.bio/contact',
            },
            {
                question: 'What is the regulatory status of the headband?',
                answer:
                    'The Dreem 3S “System” is FDA 510(k) cleared to capture electroencephalogram (EEG) data from subjects to monitor sleep architecture and aid in the diagnosis of disturbed sleep. The Dreem 3 Neuroband is FDA-registered as a biofeedback device intended to provide information about physiological parameters during sleep. It is not intended to be used for diagnosis purposes in the US and EU. The Dreem 3 Neuroband also has a consumer CE marking.',
            },
            {
                question: 'Is the headband suitable for children?',
                answer:
                    'The Dreem 3 headband is designed to fit head perimeters between 520-620mm (20,47"-24,41"), so children must have a head size that fits this specification. To ensure good signal quality, the headband must be properly adjusted, the tighter it is, the better the signal quality. For pediatric studies, it is therefore not the age of the child which needs to be taken into account but rather head size. While the headband can fit adolescents well, it may vary from one child to another in younger populations (under 11s).',
            },
            {
                question: 'Do you have an API?',
                answer:
                    'Yes, the Beacon Platform has an externalizable API available to partners. Please contact us for details!',
            },
        ],
    },
    headbandUse: {
        title: 'Headband Use',
        content: [
            {
                question:
                    "What if my participants don't have WiFi or a smartphone?",
                answer:
                    'It is possible to configure WiFi on the headband using a smartphone before giving it to your patients. They can then launch a recording using the power button located on the top arch of the headband. The headband can store up to 100 hours of recordings without any WiFi connection. However, the headband needs to be plugged in and connected to WiFi so that data can be transferred to servers and accessed on the "Dreem Portal".',
            },
            {
                question:
                    'Can I use the same headband for several participants?',
                answer:
                    'Yes, you can use the same headband for several participants. Each participant has to be attached to a user account, and each headband can be used for several user accounts. Please see cleaning recommendations in the dedicated section.',
            },
            {
                question:
                    "Can I transfer my data with a cable if I don't have a WiFi connection?",
                answer: 'No, the data is only transferable via WiFi.',
            },
            {
                question:
                    'Is it necessary to have WiFi access to upload the data?',
                answer:
                    'Yes. However, the headband can store up to 30 nights (~100 hours) before being overwritten.',
            },
            {
                question: 'Does calibration need to be done prior to each use?',
                answer:
                    'No, it does not. A live EEG section on the application can help the subject to verify that the headband is well-positioned.',
            },
            {
                question:
                    'What are the battery life and the charging time of the device?',
                answer:
                    'The average battery life of Dreem 3 is of ~ 25 hours. The charging time is 3h30 min, when fully discharged.',
            },
        ],
    },
    signal: {
        title: 'Signal',
        content: [
            {
                question: 'What is the sampling rate of the data files?',
                answer:
                    'The EEG sampling frequency of the headband is 250Hz. The accelerometer and pulse sensor sampling frequencies are 50Hz.',
            },
            {
                question: 'Is it possible to check the quality of the signal?',
                answer:
                    'You can check the signal quality during a recording by looking at the live EEG displayed on the app. If your subject or patient is relaxed with closed eyes, alpha rhythm should be visible. We cannot provide you with an impedance value. Offline EEG quality is available on the Dreem Portal.',
            },
            {
                question:
                    'What is the displayed channel on the live EEG in the app?',
                answer:
                    'The EEG derivation displayed in the app is the one with the best signal quality.',
            },
        ],
    },
    maintenance: {
        title: 'Maintenance',
        content: [
            {
                question: 'How can I clean the headband?',
                answer:
                    '70% isopropyl alcohol wipes can be used to clean the headband and the sensors. Also, this often has a beneficial effect on electrodes impedance.',
            },
        ],
    },
    storage: {
        title: 'Storage',
        content: [
            {
                question:
                    'What is the size of a recording data file after one night?',
                answer:
                    'After one night, the raw data file of the recording is around 70MB.',
            },
            {
                question: 'How is data transferred and where is it stored?',
                answer:
                    'Data is available in multiple formats, all of which are open source and can be analyzed and/or processed with adequate tools. Unprocessed raw recordings are available as binary blobs. Once processed, they are stored as HDF5 files and/or EDF. Currently, recordings must go through our servers in order for them to be accessible. The storage pipeline is as follows: First, the recording is created and stored in the headband on its internal storage. Once the recording is over and the headband is plugged in and connected to WiFi, the recording is uploaded to our servers and deleted from the headband. The raw recordings are then accessed and processed by our internal services, making them available for download afterward. The actual storage for these records is Amazon S3. Data access permissions are granted only to you and certain members of our team.',
            },
            {
                question:
                    'How long does the headband store recorded data locally?',
                answer:
                    'The headband can hold around 100 hours before overwriting its local copy of recorded data. That means you shall be able to recover all of (or most of) 10 un-uploaded nights of data without WiFi.',
            },
            {
                question: 'What format are the recordings in?',
                answer: `You can download several type of data under different formats as follow:

- Raw data encoded in binary blobs (.tar.gz). (Unfiltered)
- Raw data in h5 format. (Unfiltered)

EEG and 3D accelerometer data in EDF format. This is usually the preferred format for researchers wanting to import the file in their scoring software or for researchers using EEGlab on Matlab or a specific EEG toolbox on Python. The filtering procedure to transform an .h5 into a .edf is as follows:

- An order 2 butterworth bandpass between 0.4 and 35Hz.
- Three notches filter for 50Hz, 60Hz and 62.5 Hz of order 3
- Hypnogram in a time series format (.TXT). Sleep stages, which are determined by the sleep staging algorithm every 30s epochs, are listed chronologically in this file.
- Sleep metrics report in .CSV. It contains general information about the record (record number, start time, duration, channel quality) and sleep metrics (WASO, TST, sleep stage duration etc).`,
            },
        ],
    },
    dataAccess: {
        title: 'Data Access',
        content: [
            {
                question: 'Can I score the data myself?',
                answer:
                    'Yes. You can annotate the data in the Beacon Portal, or export data as EDF for scoring in external systems.',
            },
            {
                question: 'Does the headband have a GPS?',
                answer:
                    'No, the headband doesn’t have GPS. But as smartphones do, localization information is available from the app (in order to know the timezone). This information is not saved.',
            },
        ],
    },
    validation: {
        title: 'Validation',
        content: [
            {
                question:
                    'How many clinical trials has the Dreem device has been involved in?',
                answer:
                    'The device has been used in 5 clinical trials so far: NCT03725943 (France, n=31), NCT03657329 (US, n=67), NCT04262791 (US, n=56), NCT02956161 (France, n=24), NCT03953196 (Belgium, n=48) The device has also been included in many academic research protocols (>50).',
            },
            {
                question:
                    'Where can I find data on the performance of the algorithms?',
                answer:
                    "A paper was recently published in the peer-reviewed journal 'Sleep'. Data is also available. Ref: Arnal & al, 2020, The Dreem Headband compared to Polysomnography for EEG Signal Acquisition and Sleep Staging, Sleep [DOI: 10.1093/sleep/zsaa097](https://academic.oup.com/sleep/article/43/11/zsaa097/5841249?login=false)",
                linkTarget: '__blank',
            },
        ],
    },
}
